.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: inherit;
  border-color: inherit;
  background-color: inherit;
}

.btn {
  border-color: var(--brand-color);
  transition: var(--default-transition);
  color: var(--text-color);

  &:hover {
    background-color: var(--brand-color);
    border-color: var(--brand-color);
  }

  &:focus,
  &:active {
    &:not([class*="btn-slide"]) {
      color: var(--brand-color);
      box-shadow: none;
      background: transparent;
      border-color: var(--brand-color);
      outline: none;
    }
  }

  &.btn-xl {
    font-size: 14px;
    line-height: normal;
    padding: 16px 40px;
  }

  &.btn-lg {
    font-size: 13px;
    line-height: normal;
    padding: 12px 32px;
  }

  &.btn-md {
    font-size: 12px;
    line-height: normal;
    padding: 10px 26px;
  }

  &.btn-sm {
    font-size: 11px;
    line-height: normal;
    padding: 8px 22px;
  }

  &.btn-xs {
    font-size: 10px;
    line-height: 1.5;
    padding: 6px 16px;
  }

  /* fancy button */
  &.btn-fancy {
    &.btn-xs {
      font-size: 10px;
      letter-spacing: 0.5px;
      padding: 7px 18px;
    }

    &.btn-sm {
      font-size: 11px;
      letter-spacing: 0.5px;
      padding: 10px 24px;
    }

    &.btn-md {
      font-size: 12px;
      letter-spacing: 1px;
      padding: 12px 28px;
    }

    &.btn-lg {
      font-size: 13px;
      letter-spacing: 1px;
      padding: 13px 32px;
    }

    &.btn-xl {
      font-size: 15px;
      letter-spacing: 1px;
      padding: 18px 38px;
    }
  }

  &.btn-gradient {
    background: var(--brand-color);
    color: var(--text-color);
    border-color: transparent;
    background-size: 200% auto;

    &:active,
    &:focus {
      background: var(--brand-color);
      border-color: transparent;
      color: var(--text-color);
      background-size: 200%;
    }

    &.btn-transparent {
      background: transparent;
      border-image-source: var(--brand-color);
      border-image-slice: 1;
    }

    &:hover {
      background-position: right center;
    }
  }

  &.btn-fill {
    background-color: var(--brand-color);

    &:hover {
      background-color: transparent;
      color: var(--brand-color);
    }
  }

  &[class*="btn-slide"] {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-size: 200% auto;

    &:focus,
    &:active {
      color: var(--text-color);
    }

    &.btn-slide-filling-up,
    &.btn-slide-filling-down,
    &.btn-slide-filling-left,
    &.btn-slide-filling-right {

      &:focus,
      &:active {
        &[role="button"] {
          border-color: transparent;
        }
      }
    }

    &:hover {
      background-position: right center;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: var(--brand-color);
      transition: var(--default-transition);
    }
  }

  &.btn-slide-up {
    &::after {
      transform: translateY(100%);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateY(0);
      }
    }
  }

  &.btn-slide-down {
    &:after {
      transform: translateY(-100%);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateY(0);
      }
    }
  }

  &.btn-slide-left {
    &:after {
      transform: translateX(100%);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &.btn-slide-right {
    &:after {
      transform: translateX(-100%);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &[class*="btn-slide-filling"] {
    &:not(:active) {
      border-color: transparent;
    }
  }

  &.btn-slide-filling-up {
    &::after {
      transform: translateY(100%);
      background-color: var(--brand-color);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateY(0);
      }
    }
  }

  &.btn-slide-filling-down {
    &::after {
      transform: translateY(calc(-100% - 1px));
      background-color: var(--brand-color);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateY(0);
      }
    }
  }

  &.btn-slide-filling-left {
    &::after {
      transform: translateX(100%);
      background-color: var(--brand-color);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &.btn-slide-filling-right {
    &::after {
      transform: translateX(calc(-100% - 1px));
      background-color: var(--brand-color);
    }

    &:hover:not(:active) {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &.btn-expand {
    border: none;
    background-color: transparent;
    position: relative;
    z-index: 1;

    &:active,
    &:focus {
      color: var(--text-color) !important;
    }

    .right-icon {
      transition: var(--default-transition);
    }

    &.rounded {
      &:after {
        border-radius: 50px;
      }

      &.btn-xl {
        padding: 15px 28px 15px;

        &:after {
          width: 50px;
          height: 50px;
        }
      }

      &.btn-lg {
        padding: 14px 26px 14px;

        &:after {
          width: 46px;
          height: 46px;
        }
      }

      &.btn-md {
        padding: 7px 21px 7px;

        &:after {
          width: 32px;
          height: 32px;
        }
      }

      &.btn-sm {
        padding: 7px 20px 7px;

        &:after {
          width: 30px;
          height: 30px;
        }
      }

      &.btn-xs {
        padding: 5px 17px 5px;

        &:after {
          width: 25px;
          height: 25px;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 15%;
      height: 100%;
      z-index: -1;
      background-color: var(--brand-color);
      transition: var(--default-transition);
      transform: translateY(-50%);
    }

    &:hover {
      .right-icon {
        margin-left: 15px;
      }

      &:after {
        width: 100% !important;
        color: var(--text-color);
      }
    }
  }

  &.btn-link {
    padding: 7px 0 2px;
    position: relative;
    border: 0;
    background-color: transparent;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
    }

    &:focus-visible {
      color: var(--text-color);
      box-shadow: none;
    }

    &:hover {
      opacity: 0.7;

      &:after {
        opacity: 0.7;
      }
    }
  }

  &.text-gradient {
    background: var(--text-color);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &:focus,
    &:active {
      &[role="button"] {
        background: var(--text-color) !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &.btn-shadow {
    letter-spacing: 1px;

    &:hover {
      box-shadow: 0 8px 30px -5px rgba(0, 0, 0, 0.2) !important;
      -webkit-transform: translate3d(0, -3px, 0);
      transform: translate3d(0, -3px, 0);
      background-color: var(--brand-color) !important;
      color: var(--text-color) !important;
    }

    &:focus,
    &:active {
      box-shadow: 0 8px 30px -5px rgba(0, 0, 0, 0.2) !important;
      background-color: var(--brand-color) !important;
      color: var(--text-color) !important;
    }
  }

  &.btn-flat {
    border-color: var(--brand-color);
    color: var(--brand-color);
    border-width: 1px;
    background-color: transparent;
    box-shadow: 2px 2px 0 var(--brand-color);

    &:hover,
    &:active,
    &:focus {
      background: var(--brand-color) !important;
      border-color: transparent !important;
      box-shadow: none !important;
      color: var(--text-color) !important;
    }
  }

  &.btn-sonar {
    background-color: transparent;
    display: flex;
    font-size: 14px;
    position: relative;
    padding: 0;
    line-height: 1;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;

    &.btn-sm {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }

    &.btn-md {
      width: 50px;
      height: 50px;
      font-size: 18px;
    }

    &.btn-lg {
      width: 75px;
      height: 75px;
      font-size: 26px;
    }

    &.btn-xl {
      width: 100px;
      height: 100px;
      font-size: 25px;
    }

    &.btn-xxl {
      width: 120px;
      height: 120px;
      font-size: 34px;

      @media (max-width:var(--sm)) {
        width: 80px;
        height: 80px;
        font-size: 28px;
      }
    }

    i {
      position: relative;
      z-index: 6;
      color: var(--text-color);
      margin-left: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      --transform-translate-x: -50%;
      --transform-translate-y: -50%;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      z-index: 5;
      background: var(--brand-color);
      background-size: 200%;
    }

    &::after {
      content: "";
      width: 150%;
      height: 150%;
      opacity: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      --transform-translate-x: -50%;
      --transform-translate-y: -50%;
      border-radius: 9999px;
      z-index: 3;
      background: var(--brand-color);
      animation: video-icon-sonar 2s linear infinite;
    }

    &:hover {
      border: none;
    }
  }

  .left-icon {
    margin-right: 8px;
  }

  .right-icon {
    margin-left: 8px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[type="submit"] {
  &.loading {
    position: relative;

    &:before {
      content: "";
      background-color: rgba(255, 255, 255, 0.8) !important;
      position: absolute;
      right: 2px;
      top: 1px;
      width: calc(100% - 4px);
      height: calc(100% - 2px);
      border-radius: inherit;
      z-index: 1;
    }

    &:after {
      background: 0 0;
      content: "" !important;
      position: absolute;
      left: calc(50% - 10px) !important;
      top: calc(50% - 10px) !important;
      width: 20px !important;
      height: 20px !important;
      opacity: 1 !important;
      right: inherit !important;
      z-index: 9;
      text-align: center;
      border: 2px solid rgba(23, 23, 23, 0.2);
      border-top-color: rgba(23, 23, 23, 0.7);
      border-radius: 50%;
      animation: spin 1s linear infinite;
      -webkit-animation: spin 1s linear infinite;
    }

    &.dark {
      &:before {
        background-color: rgba(0, 0, 0, 0.8) !important;
      }

      &:after {
        border: 2px solid rgba(255, 255, 255, 0.2);
        border-top-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}